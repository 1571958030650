import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Wrapper = styled.div`
  margin: 96px auto 0;

  h2 {
    margin-bottom: 1rem;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2.2rem;
  }

  p {
    font-size: 0.9rem;
    font-weight: 300;
  }

  a {
    text-decoration: none;
    color: brown;
  }
`

const Margins = styled.div`
  margin: 0 auto;
  max-width: 1060px;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin: 0 32px;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: stretch;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    flex-direction: ${props => props.direction || "row"};
  }
`

const TextWrapper = styled.div`
  width: 50%;
  padding-right: 100px;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(100% - 16px);
    padding: 8px;
    text-align: center;
  }
`

const ImageWrapper = styled.div`
  width: 50vw;
  max-width: ${props => `${props.maxWidth}px` || "auto"};
  flex-shrink: 0;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(100% - 16px);
    padding-top: 20px 8px;
  }
`

const TitleBlock = styled.div`
  margin-right: 60px;
  padding-bottom: 10px;

  h1 {
    margin-bottom: 48px;
    text-decoration: underline;
    font-weight: 800;
    font-size: 3.5rem;
    text-transform: uppercase;
    text-align: left;
  }

  p {
    line-height: 1.5rem;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin-left: 0;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    margin: 63px 0;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    h1 {
      font-size: 2.2rem;
      word-break: break-word;
    }
  }
`

const Span = styled.div`
  width: 100%;
`

const ProductDescriptionBlock = styled.div`
  margin-bottom: 20px;
  padding-left: calc(50% - 530px);

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    padding: 16px;
  }
`

const GraySpan = styled(Span)`
  background: ${({ theme }) => theme.palette.lightGrey};
  padding: ${props => (props.pageBreak ? "16px" : "48px")};
  text-align: center;
  margin-top: 16px;

  p {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.8rem;
    color: ${({ theme }) => theme.palette.darkText};
  }
`

const ImageSpan = styled.div`
  width: 100%;
  display: flex;
  margin: 20px 8px;
  flex-wrap: wrap;
`

const ImageSpanImageWrapper = styled.div`
  width: calc(25% - 20px);
  margin: 8px;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(50% - 20px);
  }
`

const Infobox = styled.div`
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.mainBlue};
  margin-bottom: 25px;
  padding: 30px;
  max-width: 570px;

  h3 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.8rem;
    color: #000;
  }
  ul {
    list-style-type: none;
    font-size: 0.9rem;
    width: 50%;
  }

  ul li::before {
    content: "☑";
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin: 25px auto;
  }
`

const InfoboxWrapper = styled.div`
  width: 50%;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: 100%;
    padding: 20px;
  }
`

const OuterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const InfoboxFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  ul:first-child {
    padding-left: 0;
  }
`

const UpperBoxWrapper = styled.div`
  display: flex;
  width: 100%;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    display: initial;
  }
`

const LowerBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const TextBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 96px;
  width: 50%;
  flex: 1 1 0%;

  p {
    padding: 20px 0;
    flex: 1 1 0;
  }

  a {
    display: block;
    color: rgb(255, 255, 255);
    background-color: ${({ theme }) => theme.palette.mainBlue};
    width: fit-content;
    cursor: pointer;
    font-weight: 600;
    line-height: 1em;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    padding: 5px;

    :hover {
      filter: drop-shadow(rgba(0, 0, 0, 0.5) 2px 2px 5px);
    }
  }
`

const Box = styled.div`
  display: flex;
  width: calc(50% - 16px);
  margin: 8px;
  padding: 16px;
  background-color: ${({ theme }) => theme.palette.lightGrey};

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(100% - 16px);
  }
`

const LowerBox = styled.div`
  display: flex;
  width: calc(100% - 16px);
  margin: 8px;
  padding: 16px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.palette.lightGrey};

  .gatsby-image-wrapper {
    width: 239px;
    height: 100%;
    object-fit: contain !important;
    object-position: center center !important;

    @media only screen and (max-width: ${props =>
        props.theme.breakpoints.large}) {
      width: calc(100% - 16px);
    }
  }
`

const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
`

const BoxImageWrapper = styled.div`
  width: 50%;
  margin: 0px 0px 0px 16px;
`

const BottomBoxImageWrapper = styled.div`
  width: 50%;
  margin: 0px 0px 0px 16px;
  display: flex;
  justify-content: center;
`

const BoxComponent = styled.div`
  padding-bottom: 20px;
`

const SingleProductPage = () => (
  <StaticQuery
    query={graphql`
      {
        industrietore: file(name: { eq: "DSC_1879" }) {
          name
          childImageSharp {
            fluid {
              src
              aspectRatio
            }
          }
        }
        gridimage1: file(name: { eq: "osp42a" }) {
          name
          childImageSharp {
            fluid {
              src
              aspectRatio
            }
          }
        }
        gridimage2: file(name: { eq: "osf42a" }) {
          name
          childImageSharp {
            fluid {
              src
              aspectRatio
            }
          }
        }
        gridimage3: file(name: { eq: "em-ls60a" }) {
          name
          childImageSharp {
            fluid {
              src
              aspectRatio
            }
          }
        }
        allImageSharp(
          filter: {
            fluid: {
              originalName: {
                regex: "/DSC_2280|DSC_2313|HIG_7398|Normstahl_IT0005/"
              }
            }
          }
        ) {
          edges {
            node {
              fluid(maxWidth: 350, maxHeight: 350, cropFocus: CENTER) {
                originalName
                src
                aspectRatio
              }
            }
          }
        }
      }
    `}
    render={props => (
      <Layout>
      <Helmet>
        <link rel="canonical" href="https://normstahl.com/de-de/industrietore-verladeloesungen" />
      </Helmet>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <Wrapper>
          <ProductDescriptionBlock>
            <Flex direction={"column"}>
              <TitleBlock>
                <h1>INDUSTRIETORE</h1>
                <p>
                  Wer in Industrie, Gewerbe, bei Hausverwaltungen und in der Logistik etwas bewegen will, der braucht Lösungen, die den Betriebslauf von A bis Z perfekt unterstützen. Normstahl Industrie Sektionaltore und Verladesysteme bieten Ihnen bei den Faktoren Raum, Zeit und Robustheit wertvolle Pluspunkte.
                  <br />
                  <br />
                  <ul>
                    <li>Besonders robuste Decken-Sektionaltortechnik für intensive Nutzung und hohe Beanspruchung</li>
                    {/*<li>Bis zu einer Breite von 8.000 mm und einer lichten Höhe von X.000 mm Optimale Raumökonomie – jeder Zentimeter kann genutzt werden </li>*/}
                    <li>Optimaler Betriebsablauf: Die gesamte lichte Toröffnung bleibt zur Durchfahrt frei und der Raum vor und hinter der Öffnung bleibt voll erhalten. </li>
                    <li>Perfekt abgestimmt auf Ihr Gewerbe – auf Wunsch z.B. mit Extras, spezialisierten Überladebrücken und Türdichtungen</li>
                    <li>Speziell konfektionierte Türdichtungen überbrücken die Lücke zwischen Gebäude und Fahrzeugladefläche.</li>
                    <li>Neben dem Fingerklemmschutz bieten die Sektionsverbindungen weitere Vorzüge wie eine optimale Wärmedämmung und eine effektive Abdichtung.</li>
                    <li>Die verwendeten Fensterscheiben sind nicht nur witterungsbeständig, sondern optional auch kratzfest. (optional)</li>
                    <li>Sicherheitsmerkmale wie Federbruchsicherung und bei Bedarf Seilbruchsicherung (optional)</li>
                    {/*<li>Zahlreiche Ausführungen aus Stahl, Aluminium, Alu-Rahmen, jeweils mit und ohne Farbbeschichtung/Schutzlack (innen)</li>*/}
                    {/*<li>Auf Wunsch mit Fenster- und Lüftungseinsätzen für mehr Lichteinfall bzw. für  eine bessere Belüftung (z.B. in Tiefgaragen)</li>*/}
                    {<li>2 Jahre Normstahl Garantie auf das Garagentor inklusive aller Verschleißteile.</li>}
                  </ul>
                </p>
              </TitleBlock>

              <ImageWrapper>
                <Img
                  fluid={props.industrietore.childImageSharp.fluid}
                  alt="industrietore"
                />
              </ImageWrapper>
            </Flex>
          </ProductDescriptionBlock>

          {/*
          <GraySpan>
            <Margins>
              <p>
                Komfort hat jetzt zwei Aspekte: Bequemlichkeit und Sicherheit.
              </p>
            </Margins>
          </GraySpan>
          */}

          <ImageSpan>
            <ImageSpanImageWrapper>
              <Img
                fluid={props.allImageSharp.edges[0].node.fluid}
                alt="industrietore"
              />
            </ImageSpanImageWrapper>
            <ImageSpanImageWrapper>
              <Img
                fluid={props.allImageSharp.edges[1].node.fluid}
                alt="industrietore"
              />
            </ImageSpanImageWrapper>
            <ImageSpanImageWrapper>
              <Img
                fluid={props.allImageSharp.edges[2].node.fluid}
                alt="industrietore"
              />
            </ImageSpanImageWrapper>
            <ImageSpanImageWrapper>
              <Img
                fluid={props.allImageSharp.edges[3].node.fluid}
                alt="industrietore"
              />
            </ImageSpanImageWrapper>
          </ImageSpan>

          <Margins>
            <BoxComponent>
              <UpperBoxWrapper>
                <Box>
                  <TextBlock>
                    <Title>OSP42A Industrietor</Title>
                    <p>
                    Dieses isolierte Paneeltor ist äußerst stabil und mit
                    seinen optimalen Wärmedämmeigenschaften besonders
                    energiesparend.
                    </p>
                    <a href="https://normstahl.com/de-de/industrietore-verladeloesungen/osp42a" target="_blank" rel="noopener noreferrer">
                      Mehr erfahren
                    </a>
                  </TextBlock>
                  <BoxImageWrapper>
                    <Img
                      fluid={props.gridimage1.childImageSharp.fluid}
                      alt="OSP42A Industrial Door"
                    />
                  </BoxImageWrapper>
                </Box>
                <Box>
                  <TextBlock>
                    <Title>OSF42A Industrietor</Title>
                    <p>
                    Auch ein Industriesektionaltor kann visuelle Akzente setzen
                    und einen architektonischen Anspruch vertreten.
                    </p>
                    <a href="https://normstahl.com/de-de/industrietore-verladeloesungen/OSF42A" target="_blank" rel="noopener noreferrer">
                      Mehr erfahren
                    </a>
                  </TextBlock>
                  <BoxImageWrapper>
                    <Img
                      fluid={props.gridimage2.childImageSharp.fluid}
                      alt="OSP42A Industrial Door"
                    />
                  </BoxImageWrapper>
                </Box>
              </UpperBoxWrapper>
              <LowerBoxWrapper>
                <LowerBox>
                  <TextBlock>
                    <Title>Normstahl Verladesystem</Title>
                    <p>
                    Unsere Überladebrücken vermeiden lange Wartezeiten bei
                    Verladungen. Die Lücke zwischen Gebäude und Ladefläche auf
                    dem Fahrzeug wird durch dieses Verladesystem sicher
                    überbrückt.
                    </p>
                    <a href="https://normstahl.com/de-de/industrietore-verladeloesungen" target="_blank" rel="noopener noreferrer">
                      Mehr erfahren
                    </a>
                  </TextBlock>
                  <BottomBoxImageWrapper>
                    <Img
                      fluid={props.gridimage3.childImageSharp.fluid}
                      alt="OSP42A Industrial Door"
                    />
                  </BottomBoxImageWrapper>
                </LowerBox>
              </LowerBoxWrapper>
            </BoxComponent>
            {/*
            <OuterWrapper>
              <TextWrapper>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet,
                  consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                  invidunt ut labore et dolore magna aliquyam erat, sed diam
                  voluptua. At vero eos et accusam.
                  <br />
                  <br />
                  At vero eos et accusam et justo duo dolores et ea rebum. Stet
                  clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
                  dolor sit amet. Lorem ipsum dolor sit amet, consetetur
                  sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                  labore et dolore.
                  <br />
                  <br />
                  Magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                  et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                  no sea takimata sanctus est Lorem ipsum dolor sit amet.
                </p>
              </TextWrapper>

              <InfoboxWrapper>
                <Infobox>
                  <h3>Lorem ipsum dolor sit</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit amet.
                  </p>
                </Infobox>

                  <Infobox>
                  <h3>Lorem ipsum dolor sit</h3>
                  <InfoboxFlex>
                    <ul>
                      <li>Lorem ipsum dolor sit</li>
                      <li>Consectetur adipiscing</li>
                      <li>Aliquam laoreet ligula</li>
                      <li>Dignissim malesuada</li>
                    </ul>
                    <ul>
                      <li>Lorem ipsum dolor sit</li>
                      <li>Consectetur adipiscing</li>
                      <li>Aliquam laoreet ligula</li>
                      <li>Dignissim malesuada</li>
                    </ul>
                  </InfoboxFlex>
                </Infobox>

              </InfoboxWrapper>
            </OuterWrapper>
            */}
          </Margins>
        </Wrapper>
      </Layout>
    )}
  />
)

export default SingleProductPage
